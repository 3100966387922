import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomCursor from "../components/Cursor";
import ProjectMiniature from "../components/ProjectMiniature";
import "../styles/components/page-projects.scss";
import Bubble from "../media/creative-coding/Bubble/bubble-miniature.png";
import Reflexion from "../media/creative-coding/Reflexion/reflexion-miniature.png";
import Sculpture from "../media/creative-coding/Sculpture/sculpture-miniature.png";
import Snake from "../media/creative-coding/Snake/snake-miniature.png";
import Triangle from "../media/creative-coding/Triangle/triangle-miniature.png";
import Wave from "../media/creative-coding/Wave/wave-miniature.png";
import Wind from "../media/creative-coding/Wind/wind-miniature.png";

export default function CreativeCoding() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="window" style={{ paddingTop: "20vh" }}>
      <div className="project spacing-block">
        <h1 className="page-title text-8xl">{t("creative_coding.title")}</h1>
        <div className="project-elements">
          <ProjectMiniature
            image={Reflexion}
            name="Reflexion"
            link="reflexion"
          />
          <ProjectMiniature
            image={Sculpture}
            name="Sculpture"
            link="sulpture"
          />
          <ProjectMiniature image={Bubble} name="Bubble" link="bubble" />
          <ProjectMiniature image={Snake} name="Snake" link="snake" />
          <ProjectMiniature image={Triangle} name="Triangle" link="triangle" />
          <ProjectMiniature image={Wind} name="Wind" link="wind" />
          <ProjectMiniature image={Wave} name="Wave" link="wave" />
        </div>
      </div>
      <CustomCursor />
    </div>
  );
}
