import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    /* resources: {
      en: {
        translation: {
          menu: {
            subtitle: "Creative Designer for ambitious project",
          },
          introduction: {
            hello: "Hello 👋",
          },
        },
      },
      fr: {
        translation: {
          menu: {
            subtitle: "Creative Designer pour projets ambitieux",
          },
          introduction: {
            hello: "Bonjour 👋",
          },
        },
      },
    }, */
  });

export default i18n;
