import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../../styles/components/project.scss";
import CustomCursor from "../../Cursor";
import { Link } from "react-router-dom";
import ImageFull from "../../../media/art-direction/jo-2024/jo-2024-full.png";
import ImageHalf1 from "../../../media/art-direction/jo-2024/jo-2024-half-1.png";
import ImageHalf2 from "../../../media/art-direction/jo-2024/jo-2024-half-2.png";
import ImageHalf3 from "../../../media/art-direction/jo-2024/jo-2024-half-3.png";
import ImageHalf4 from "../../../media/art-direction/jo-2024/jo-2024-half-4.png";
import ImageHalf5 from "../../../media/art-direction/jo-2024/jo-2024-half-5.png";
import ImageHalf6 from "../../../media/art-direction/jo-2024/jo-2024-half-6.png";
import ImageHalf7 from "../../../media/art-direction/jo-2024/jo-2024-half-7.png";
import ImageHalf8 from "../../../media/art-direction/jo-2024/jo-2024-half-8.png";
import ImageHalf9 from "../../../media/art-direction/jo-2024/jo-2024-half-9.png";
import ImageHalf10 from "../../../media/art-direction/jo-2024/jo-2024-half-10.png";

export default function Jo2024() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="project-focus spacing-block" style={{ paddingTop: "10vh" }}>
      <div className="content">
        <h2 className="title text-6xl">
          {t("art_direction.jo2024.project_name")}
        </h2>
        <h3 className="year text-4xl">2019</h3>
        {/* IMAGES */}
        <div className="images">
          {/* FULL */}
          <div className="row">
            <div className="col-full">
              <img src={ImageFull} alt="" />
            </div>
          </div>
          {/* END FULL */}
        </div>
        {/* END IMAGES */}

        {/* DESCRIPTION */}
        <div className="description">
          <h4 className="text-4xl">{t("art_direction.jo2024.title")}</h4>
          <p className="text-2xl">{t("art_direction.jo2024.text")}</p>
        </div>

        {/* IMAGES */}
        <div className="images">
          {/* HALF */}
          <div className="row">
            <div className="col">
              <img src={ImageHalf1} alt="" />
            </div>
            <div className="col">
              <img src={ImageHalf2} alt="" />
            </div>
          </div>
          {/* END HALF */}
          {/* HALF */}
          <div className="row">
            <div className="col">
              <img src={ImageHalf3} alt="" />
            </div>
            <div className="col">
              <img src={ImageHalf4} alt="" />
            </div>
          </div>
          {/* END HALF */}
          {/* HALF */}
          <div className="row">
            <div className="col">
              <img src={ImageHalf5} alt="" />
            </div>
            <div className="col">
              <img src={ImageHalf6} alt="" />
            </div>
          </div>
          {/* END HALF */}
          {/* HALF */}
          <div className="row">
            <div className="col">
              <img src={ImageHalf7} alt="" />
            </div>
            <div className="col">
              <img src={ImageHalf8} alt="" />
            </div>
          </div>
          {/* END HALF */}
          {/* HALF */}
          <div className="row">
            <div className="col">
              <img src={ImageHalf9} alt="" />
            </div>
            <div className="col">
              <img src={ImageHalf10} alt="" />
            </div>
          </div>
          {/* END HALF */}
        </div>
        {/* END IMAGES */}
        <Link to="/art-direction" style={{ color: "inherit" }}>
          <h3 className="close-text text-4xl animated-cursor">
            {t("art_direction.back")}
          </h3>
        </Link>
      </div>
      <CustomCursor />
    </div>
  );
}
