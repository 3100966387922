import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
// import { preloadImages } from "../scripts/utils";
import { useTranslation } from "react-i18next";
import "../styles/components/menu.scss";
import Img1 from "../media/art-direction/pat/pat-full-1.png";
import Img2 from "../media/creative-coding/Bubble/bubble-miniature.png";
import Img3 from "../media/art-direction/mizu/mizu-miniature.png";
import Img4 from "../media/web-development/Fluff/fluff-full-1.png";
import Img5 from "../media/creative-coding/Wind/wind-miniature.png";
import Img6 from "../media/art-direction/jo-2024/jo-2024-miniature.png";
import Img7 from "../media/advertising/bottega-venetta/bottega-veneta_full.png";
import Img8 from "../media/creative-coding/Wave/wave-miniature.png";
import Img9 from "../media/art-direction/loki/loki-miniature.png";

const lngs = {
  fr: { nativeName: "FR" },
  en: { nativeName: "EN" },
};

const MenuComponent = () => {
  const { t, i18n } = useTranslation();

  const main = useRef();
  const frame = useRef();
  const menu = useRef();
  const overlayPath = useRef();
  const artDirection = useRef();
  const advertising = useRef();
  const creativeCoding = useRef();
  const webDevelopment = useRef();
  const painting = useRef();

  // eslint-disable-next-line
  let isAnimating = false;

  const handleOpenMenu = () => {
    main.current.classList.add("main-open");
    document.body.classList.add("disable-scroll");
    gsap
      .timeline({
        onComplete: () => (isAnimating = false),
      })
      .set(overlayPath.current, {
        attr: { d: "M 0 100 V 100 Q 50 100 100 100 V 100 z" },
      })
      .to(
        overlayPath.current,
        {
          duration: 0.8,
          ease: "power4.in",
          attr: { d: "M 0 100 V 50 Q 50 0 100 50 V 100 z" },
        },
        0
      )
      .to(overlayPath.current, {
        duration: 0.3,
        ease: "power2",
        attr: { d: "M 0 100 V 0 Q 50 0 100 0 V 100 z" },
        onComplete: () => {
          frame.current.classList.add("frame--menu-open");
          menu.current.classList.add("menu-wrap--open");
        },
      })
      // now reveal
      .set(
        [
          artDirection.current,
          advertising.current,
          creativeCoding.current,
          webDevelopment.current,
          painting.current,
        ],
        {
          opacity: 0,
        }
      )
      .to(overlayPath.current, {
        attr: { d: "M 0 0 V 100 Q 50 100 100 100 V 0 z" },
      })
      .to(overlayPath.current, {
        duration: 0.3,
        ease: "power2.in",
        attr: { d: "M 0 0 V 50 Q 50 0 100 50 V 0 z" },
      })
      .to(overlayPath.current, {
        duration: 0.8,
        ease: "power4",
        attr: { d: "M 0 0 V 0 Q 50 0 100 0 V 0 z" },
      })
      // Menu item translate animation
      .to(
        [
          artDirection.current,
          advertising.current,
          creativeCoding.current,
          webDevelopment.current,
          painting.current,
        ],
        {
          duration: 1.1,
          ease: "power4",
          startAt: { y: 150 },
          y: 0,
          opacity: 1,
          stagger: 0.05,
        },
        ">-=1.1"
      );
  };

  const handleClosenMenu = () => {
    document.body.classList.remove("disable-scroll");
    gsap
      .timeline({
        onComplete: () => (isAnimating = false),
      })
      .set(overlayPath.current, {
        attr: { d: "M 0 100 V 0 Q 50 0 100 0 V 100 z" },
      })
      .to(
        overlayPath.current,
        {
          duration: 0.8,
          ease: "power4.in",
          attr: { d: "M 0 100 V 50 Q 50 0 100 50 V 100 z" },
        },
        0
      )
      .to(overlayPath.current, {
        duration: 0.3,
        ease: "power2",
        attr: { d: "M 0 100 V 100 Q 50 100 100 100 V 100 z" },
        onComplete: () => {
          frame.current.classList.remove("frame--menu-open");
          menu.current.classList.remove("menu-wrap--open");
        },
      })
      // now reveal
      .set(overlayPath.current, {
        attr: { d: "M 0 100 V 0 Q 50 0 100 0 V 100 z" },
      })
      .to(overlayPath.current, {
        duration: 0.3,
        ease: "power2.in",
        attr: { d: "M 0 100 V 50 Q 50 100 100 50 V 100 z" },
      })
      .to(overlayPath.current, {
        duration: 0.8,
        ease: "power4",
        attr: { d: "M 0 100 V 100 Q 50 100 100 100 V 100 z" },
        onComplete: () => {
          main.current.classList.remove("main-open");
        },
      })
      // menu item translate animation
      .to(
        [
          artDirection.current,
          advertising.current,
          creativeCoding.current,
          webDevelopment.current,
          painting.current,
        ],
        {
          duration: 0.8,
          ease: "power2.in",
          y: 100,
          opacity: 0,
          stagger: -0.05,
        },
        0
      );
  };

  return (
    <main ref={main}>
      <div className="frame" ref={frame}>
        {/* <div className="blank-space"></div> */}
        {/* <div className="logo">
          <svg viewBox="0 0 439.09 439.32">
            <title>logo</title>
            <path
              d="M197,30c5.51,2.14,11.49,3.53,16.45,6.59,11,6.8,14.87,17.82,15.49,30.25,1.18,23.72-2.83,46.87-8.63,69.65-6.64,26.1-14.14,52-21.84,77.8-2.71,9.06-1.31,15.88,5.45,22.57,7.74,7.67,16.89,12.27,27.1,15.18a220.39,220.39,0,0,1,69,33c37.73,27.15,41.85,65.4,26.4,102.27-7.76,18.52-20.7,33.26-35.11,46.84-7.27,6.85-14.66,13.57-22.07,20.28-7.85,7.12-7.89,7.08-1.2,14.88-8-.49-11.21-6.58-6.48-13.17,4.17-5.8,9.06-11.09,13.64-16.59,13.66-16.42,27-33.09,34.51-53.44a88.72,88.72,0,0,0,5.25-37.31c-1.22-17.82-10.94-30.6-24.32-41.28-15.39-12.28-33.09-20.4-50.8-28.52q-18.62-8.52-37.23-17c-20.83-9.55-29-26.67-23.85-49,6.74-29.16,13.93-58.25,19.49-87.65,2.88-15.25,3-31.08,3.67-46.68.19-4.28-1.4-8.89-3.11-13-2.28-5.43-7.19-7.5-12.34-4.69a52.08,52.08,0,0,0-14,10.8c-13.58,15.39-22.58,33.69-31.32,52C115.16,178.29,95.3,235.18,74,291.48,66.2,312,57.74,332.26,49.41,352.56c-1.28,3.11-3.57,5.79-5.38,8.67l-1.65-.11c-.8-2.38-2-4.71-2.32-7.15-1.78-15.37-4.34-30.74-4.81-46.16C33.64,254.88,32.06,201.93,31.91,149c-.09-29.76,2.53-59.52,4.23-89.27.31-5.59,1.76-11.17,3.24-16.61.52-1.9,3-4.87,4.07-4.67,2,.39,4.66,2.42,5.23,4.32,1.86,6.18,3.63,12.54,4.11,18.94,1.69,22.07,2.36,44.23,4.28,66.29,2,23.36,5.07,46.64,7.7,70A43.32,43.32,0,0,0,66.2,203c2.78-4.24,5.09-7.21,6.82-10.5,11.06-21.06,21.78-42.32,33-63.28,14.44-26.88,29.73-53.25,50.54-75.88A138.59,138.59,0,0,1,169.4,41.47,50.75,50.75,0,0,1,195,30Z"
              transform="translate(-31.91 -30)"
            />
            <path
              d="M471,431c-.43.85-.92,1.68-1.27,2.56-4,10-13.73,15.35-24.05,13.2a20.89,20.89,0,0,1-16.43-20.46c.11-10,7.15-18.59,16.6-20.28,10.72-1.92,20.09,3.3,23.94,13.32.35.91.81,1.77,1.21,2.66Z"
              transform="translate(-31.91 -30)"
            />
          </svg>
        </div> */}
        {/* <div className="language__switcher text-base">
          <b>EN</b> / FR
        </div> */}
        <div className="language__switcher text-base animated-cursor">
          {Object.keys(lngs).map((lng) => (
            <button
              key={lng}
              style={{
                fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
              }}
              type="submit"
              onClick={() => i18n.changeLanguage(lng)}
            >
              {lngs[lng].nativeName}
            </button>
          ))}
        </div>
        <Link
          className="title animated-cursor"
          to={`/`}
          onClick={handleClosenMenu}
        >
          <h3 className="text-3xl">Benjamin Arpage</h3>
          <h4 className="text-base">{t("menu.subtitle")}</h4>
        </Link>
        <button
          className="unbutton button__menu animated-cursor"
          aria-label="Open menu"
          onClick={handleOpenMenu}
        >
          <svg viewBox="0 0 19 12">
            <path d="m.742 3.26.485.874c.043-.024.13-.07.26-.136.22-.11.476-.233.765-.361A22.92 22.92 0 0 1 4.997 2.62c4.476-1.34 8.75-1.219 12.241 1.1.18.12.357.245.531.376l.6-.8a12.46 12.46 0 0 0-.578-.408C14.008.375 9.443.246 4.71 1.663c-1.037.31-2 .675-2.865 1.06a18.83 18.83 0 0 0-1.103.536Z" />
            <path d="m.742 6.748.485.874c.043-.023.13-.07.26-.135.22-.111.476-.233.765-.362A22.92 22.92 0 0 1 4.997 6.11c4.476-1.34 8.75-1.22 12.241 1.1.18.12.357.245.531.375l.6-.8a12.46 12.46 0 0 0-.578-.408C14.008 3.864 9.443 3.735 4.71 5.152c-1.037.31-2 .675-2.865 1.06a18.83 18.83 0 0 0-1.103.536Z" />
            <path d="m.742 10.237.485.874c.043-.024.13-.07.26-.136.22-.11.476-.232.765-.36a22.92 22.92 0 0 1 2.745-1.016c4.476-1.34 8.75-1.22 12.241 1.1.18.12.357.244.531.375l.6-.8a12.46 12.46 0 0 0-.578-.408C14.008 7.353 9.443 7.224 4.71 8.64c-1.037.31-2 .674-2.865 1.06a18.83 18.83 0 0 0-1.103.536Z" />
          </svg>
        </button>
        <button
          className="unbutton button__close animated-cursor"
          onClick={handleClosenMenu}
        >
          <svg width="25" height="16" viewBox="0 0 25 16">
            <path d="M2.238 7.079h2.727M2.482 9.496l-.666-2.7" />
            <path d="M23.753 5.403s-1.87 16.88-22.03 1.785" />
          </svg>
        </button>
      </div>
      <div className="menu__wrap" ref={menu}>
        <div className="tiles">
          <div className="tiles__line">
            <div
              className="tiles__line-img tiles__line-img--large"
              style={{ backgroundImage: `url(${Img4})` }}
            ></div>
            <div
              className="tiles__line-img"
              style={{ backgroundImage: `url(${Img5})` }}
            ></div>
            <div
              className="tiles__line-img"
              style={{ backgroundImage: `url(${Img6})` }}
            ></div>
            <div
              className="tiles__line-img tiles__line-img--large"
              style={{ backgroundImage: `url(${Img4})` }}
            ></div>
            <div
              className="tiles__line-img"
              style={{ backgroundImage: `url(${Img5})` }}
            ></div>
            <div
              className="tiles__line-img"
              style={{ backgroundImage: `url(${Img6})` }}
            ></div>
          </div>
          <div className="tiles__line">
            <div
              className="tiles__line-img tiles__line-img--large"
              style={{ backgroundImage: `url(${Img1})` }}
            ></div>
            <div
              className="tiles__line-img"
              style={{ backgroundImage: `url(${Img2})` }}
            ></div>
            <div
              className="tiles__line-img"
              style={{ backgroundImage: `url(${Img3})` }}
            ></div>
            <div
              className="tiles__line-img tiles__line-img--large"
              style={{ backgroundImage: `url(${Img1})` }}
            ></div>
            <div
              className="tiles__line-img"
              style={{ backgroundImage: `url(${Img2})` }}
            ></div>
            <div
              className="tiles__line-img"
              style={{ backgroundImage: `url(${Img3})` }}
            ></div>
          </div>
          <div className="tiles__line">
            <div
              className="tiles__line-img tiles__line-img--large"
              style={{ backgroundImage: `url(${Img7})` }}
            ></div>
            <div
              className="tiles__line-img"
              style={{ backgroundImage: `url(${Img8})` }}
            ></div>
            <div
              className="tiles__line-img"
              style={{ backgroundImage: `url(${Img9})` }}
            ></div>
            <div
              className="tiles__line-img tiles__line-img--large"
              style={{ backgroundImage: `url(${Img7})` }}
            ></div>
            <div
              className="tiles__line-img"
              style={{ backgroundImage: `url(${Img8})` }}
            ></div>
            <div
              className="tiles__line-img"
              style={{ backgroundImage: `url(${Img9})` }}
            ></div>
          </div>
        </div>
        <nav className="menu">
          <Link
            to={`/art-direction`}
            className="menu__item text-8xl animated-cursor"
            ref={artDirection}
            onClick={handleClosenMenu}
          >
            {t("menu.art_direction")}
          </Link>
          <Link
            to={`/advertising`}
            className="menu__item text-8xl animated-cursor"
            ref={advertising}
            onClick={handleClosenMenu}
          >
            {t("menu.advertising")}
          </Link>
          <Link
            to={`/creative-coding`}
            className="menu__item text-8xl animated-cursor"
            ref={creativeCoding}
            onClick={handleClosenMenu}
          >
            {t("menu.creative_coding")}
          </Link>
          <Link
            to={`/web-development`}
            className="menu__item text-8xl animated-cursor"
            ref={webDevelopment}
            onClick={handleClosenMenu}
          >
            {t("menu.web_development")}
          </Link>
          <Link
            to={`/painting`}
            className="menu__item text-8xl animated-cursor"
            ref={painting}
            onClick={handleClosenMenu}
          >
            {t("menu.painting")}
          </Link>
        </nav>
      </div>
      <svg
        className="overlay"
        width="100%"
        height="100%"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <path
          ref={overlayPath}
          className="overlay__path"
          vectorEffect="non-scaling-stroke"
          d="M 0 100 V 100 Q 50 100 100 100 V 100 z"
        />
      </svg>
    </main>
  );
};

export default MenuComponent;
