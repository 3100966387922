import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import "../../../styles/components/project.scss";
import CustomCursor from "../../Cursor";
import { Link } from "react-router-dom";
import Video from "../../../media/art-direction/loki/loki.mp4";
import ImageFull1 from "../../../media/art-direction/loki/loki-full.png";
import ImageFull2 from "../../../media/art-direction/loki/loki-full-2.png";
import ImageHalf1 from "../../../media/art-direction/loki/loki-half-1.png";
import ImageHalf2 from "../../../media/art-direction/loki/loki-half-2.png";
import ImageHalf3 from "../../../media/art-direction/loki/loki-half-3.png";
import ImageHalf4 from "../../../media/art-direction/loki/loki-half-4.png";

export default function Loki() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="project-focus spacing-block" style={{ paddingTop: "10vh" }}>
      <div className="content">
        <h2 className="title text-6xl">
          {t("art_direction.loki.project_name")}
        </h2>
        <h3 className="year text-4xl">2018</h3>
        {/* VIDEO */}
        <video
          className="video"
          autoPlay
          muted
          loop
          controls
          src={Video}
        ></video>

        {/* DESCRIPTION */}
        <div className="description">
          <h4 className="text-4xl">{t("art_direction.loki.title")}</h4>
          <h5 className="text-xl">{t("art_direction.loki.subtitle")}</h5>
          <p className="text-2xl">
            <Trans i18nKey="art_direction.loki.text">
              A platform that aims to facilitate urban travel by proposing the
              most suitable itinerary according to transportation congestion and
              its ecological impact.
              <br />
              The modes of transportation offered are the public transportation
              available in the city concerned, but also rental vehicles, walking
              and soft means of transportation. To this end, a gambling system
              is used, with points called LOKI, which allow the passenger to
              benefit from discounts in partner stores in his city, but also the
              possibility of using his points to plant trees.
              <br />
              These LOKI are earned as we travel with the application and
              depending on whether the trip has a good or bad ecological impact
              it will earn more or less points.
            </Trans>
            <br />
            <br />
            {t("art_direction.loki.link")}
            <a
              className="animated-cursor"
              href="https://www.ecv.fr/retour-sur-lideathon-nantais-deux-semaines-de-competition-sur-les-innovations-digitales-post-confinement/"
            >
              https://www.ecv.fr/retour-sur-lideathon-nantais-deux-semaines-de-competition-sur-les-innovations-digitales-post-confinement/
            </a>
          </p>
        </div>

        {/* IMAGES */}
        <div className="images">
          {/* FULL */}
          <div className="row">
            <div className="col-full">
              <img src={ImageFull1} alt="" />
            </div>
          </div>
          {/* END FULL */}
          {/* <div style={{ marginBottom: "5vh" }}></div> */}
          {/* FULL */}
          <div className="row">
            <div className="col-full">
              <img src={ImageFull2} alt="" />
            </div>
          </div>
          {/* END FULL */}
          {/* HALF */}
          <div className="row">
            <div className="col">
              <img src={ImageHalf1} alt="" />
            </div>
            <div className="col">
              <img src={ImageHalf2} alt="" />
            </div>
          </div>
          {/* END HALF */}
          {/* HALF */}
          <div className="row">
            <div className="col">
              <img src={ImageHalf3} alt="" />
            </div>
            <div className="col">
              <img src={ImageHalf4} alt="" />
            </div>
          </div>
          {/* END HALF */}
        </div>
        {/* END IMAGES */}
        <Link to="/art-direction" style={{ color: "inherit" }}>
          <h3 className="close-text text-4xl animated-cursor">
            {t("art_direction.back")}
          </h3>
        </Link>
      </div>
      <CustomCursor />
    </div>
  );
}
