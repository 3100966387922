import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomCursor from "../components/Cursor";
import ProjectMiniature from "../components/ProjectMiniature";
import "../styles/components/page-projects.scss";
import LostFlowers from "../media/painting/23/n23-miniature.png";
import UltraViolet from "../media/painting/22/n22-miniature.png";
import BrokenHeart from "../media/painting/21/21-miniature.png";
import UnderPressure from "../media/painting/20/n20-miniature.png";
import TrunkOfTheNightmare from "../media/painting/19/n19-miniature.png";
import FirstBirds from "../media/painting/18/n18-miniature.png";
import StrollInTheSpirit from "../media/painting/17/n17-miniature.png";
import BlueDreamInACage from "../media/painting/16/n16-miniature.png";
import GroundAfterRain from "../media/painting/15/n15-miniature.png";
import WrongWay from "../media/painting/14/n14-miniature.png";
import DivingIntoTheDepths from "../media/painting/13/n13-miniature.png";
import LookAtme from "../media/painting/11/11-miniature.png";
import Disillusion from "../media/painting/5/n5-miniature.png";

export default function Painting() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="window" style={{ paddingTop: "20vh" }}>
      <div className="project spacing-block">
        <h1 className="page-title text-8xl">{t("painting.title")}</h1>
        <div className="project-elements">
          <ProjectMiniature
            image={LostFlowers}
            name="Lost Flowers"
            link="lost-flowers"
          />
          <ProjectMiniature
            image={UltraViolet}
            name="Ultra Violet"
            link="ultra-violet"
          />
          <ProjectMiniature
            image={BrokenHeart}
            name="Broken Heart"
            link="broken-heart"
          />
          <ProjectMiniature
            image={UnderPressure}
            name="Under Pressure"
            link="under-pressure"
          />
          <ProjectMiniature
            image={TrunkOfTheNightmare}
            name="The Trunk of the nightmare"
            link="trunk-of-the-nightmare"
          />
          <ProjectMiniature
            image={FirstBirds}
            name="Free Birds"
            link="free-birds"
          />
          <ProjectMiniature
            image={StrollInTheSpirit}
            name="Stroll in the spirit"
            link="stroll-in-the-spirit"
          />
          <ProjectMiniature
            image={BlueDreamInACage}
            name="Blue Dream in a Cage"
            link="blue-dream-in-a-cage"
          />
          <ProjectMiniature
            image={GroundAfterRain}
            name="Ground after rain"
            link="ground-after-rain"
          />
          <ProjectMiniature
            image={WrongWay}
            name="Wrong way"
            link="wrong-way"
          />
          <ProjectMiniature
            image={DivingIntoTheDepths}
            name="Diving into the depths"
            link="diving-into-the-depths"
          />
          <ProjectMiniature
            image={LookAtme}
            name="Look at me"
            link="look-at-me"
          />
          <ProjectMiniature
            image={Disillusion}
            name="Disillusion"
            link="disillusion"
          />
        </div>
      </div>
      <CustomCursor />
    </div>
  );
}
