import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../../styles/components/project.scss";
import CustomCursor from "../../Cursor";
import { Link } from "react-router-dom";
import Video from "../../../media/web-development/Lengow/lengow.mp4";
import ImageFull1 from "../../../media/web-development/Lengow/lengow_full.png";
import ImageFull2 from "../../../media/web-development/Lengow/lengow_full-2.png";
import ImageHalf1 from "../../../media/web-development/Lengow/lengow-half-1.png";
import ImageHalf2 from "../../../media/web-development/Lengow/lengow-half-2.png";

export default function Lengow() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="project-focus spacing-block" style={{ paddingTop: "10vh" }}>
      <div className="content">
        <h2 className="title text-6xl">Lengow</h2>
        <h3 className="year text-4xl">2021 — 2023</h3>
        {/* VIDEO */}
        <video
          className="video"
          autoPlay
          muted
          loop
          controls
          src={Video}
        ></video>

        {/* DESCRIPTION */}
        <div className="description">
          <h4 className="text-4xl">{t("web_development.lengow.title")}</h4>
          <p className="text-2xl">{t("web_development.lengow.text")}</p>
        </div>

        {/* IMAGES */}
        <div className="images">
          {/* FULL */}
          <div className="row">
            <div className="col-full">
              <img src={ImageFull1} alt="" />
            </div>
          </div>
          {/* END FULL */}
          {/* HALF */}
          <div className="row">
            <div className="col">
              <img src={ImageHalf1} alt="" />
            </div>
            <div className="col">
              <img src={ImageHalf2} alt="" />
            </div>
          </div>
          {/* END HALF */}
          {/* FULL */}
          <div className="row">
            <div className="col-full">
              <img src={ImageFull2} alt="" />
            </div>
          </div>
          {/* END FULL */}
        </div>
        {/* END IMAGES */}
        <Link to="/web-development" style={{ color: "inherit" }}>
          <h3 className="close-text text-4xl animated-cursor">
            {t("web_development.back")}
          </h3>
        </Link>
      </div>
      <CustomCursor />
    </div>
  );
}
