import React, { useEffect } from "react";
import { Cursor } from "../scripts/cursor";

export default function CustomCursor() {
  useEffect(() => {
    // Initialize custom cursor
    new Cursor(document.querySelectorAll(".cursor"), ".animated-cursor");
  }, []);

  return (
    <div>
      <svg className="cursor" width="100" height="100" viewBox="0 0 100 100">
        <defs>
          <filter
            id="cursor-filter"
            x="-50%"
            y="-50%"
            width="200%"
            height="200%"
            filterUnits="objectBoundingBox"
          >
            <feTurbulence
              type="fractalNoise"
              baseFrequency="0"
              numOctaves="1"
              result="warp"
            />
            <feOffset dx="0" result="warpOffset" />
            <feDisplacementMap
              xChannelSelector="R"
              yChannelSelector="G"
              scale="40"
              in="SourceGraphic"
              in2="warpOffset"
            />
          </filter>
        </defs>
        <circle className="cursor__inner" cx="50" cy="50" r="10" />
      </svg>
      <svg
        className="cursor"
        width="100"
        height="100"
        viewBox="0 0 100 100"
        data-amt="0.15"
        data-radius-enter="40"
      >
        <circle className="cursor__inner" cx="50" cy="50" r="10" />
      </svg>
    </div>
  );
}
