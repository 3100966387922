import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../../styles/components/project.scss";
import CustomCursor from "../../Cursor";
import { Link } from "react-router-dom";
import Video from "../../../media/creative-coding/Triangle/triangle.mp4";

export default function Triangle() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="project-focus spacing-block" style={{ paddingTop: "10vh" }}>
      <div className="content">
        <h2 className="title text-6xl">Triangle</h2>
        <h3 className="year text-4xl">2021</h3>
        {/* VIDEO */}
        <video
          className="video"
          autoPlay
          muted
          loop
          controls
          src={Video}
        ></video>

        {/* DESCRIPTION */}
        <div className="description">
          <h4 className="text-4xl">{t("creative_coding.triangle.title")}</h4>
          <p className="text-2xl" style={{ textAlign: "center" }}>
            {t("creative_coding.triangle.text")}
          </p>
        </div>

        <Link to="/creative-coding" style={{ color: "inherit" }}>
          <h3 className="close-text text-4xl animated-cursor">
            {t("creative_coding.back")}
          </h3>
        </Link>
      </div>
      <CustomCursor />
    </div>
  );
}
