import { Link } from "react-router-dom";
import "../styles/components/project-miniature.scss";

export default function ProjectMiniature({ image, name, link }) {
  return (
    <Link className="project-item animated-cursor" to={link}>
      <div className="img-box">
        <img src={image} alt="" />
      </div>
      <h3 className="text-4xl">{name}</h3>
    </Link>
  );
}
