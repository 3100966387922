import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../../styles/components/project.scss";
import CustomCursor from "../../Cursor";
import { Link } from "react-router-dom";
import Video from "../../../media/art-direction/sandra-martin/sandra-martin.mp4";
import ImageFull from "../../../media/art-direction/sandra-martin/sandra-martin-full.png";
import ImageHalf1 from "../../../media/art-direction/sandra-martin/sandra-martin-half-1.png";
import ImageHalf2 from "../../../media/art-direction/sandra-martin/sandra-martin-half-2.png";

export default function SandraMartin() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="project-focus spacing-block" style={{ paddingTop: "10vh" }}>
      <div className="content">
        <h2 className="title text-6xl">
          {t("art_direction.sandra_martin.project_name")}
        </h2>
        <h3 className="year text-4xl">2019</h3>
        {/* VIDEO */}
        <video
          className="video"
          autoPlay
          muted
          loop
          controls
          src={Video}
        ></video>

        {/* DESCRIPTION */}
        <div className="description">
          <h4 className="text-4xl">{t("art_direction.sandra_martin.title")}</h4>
          <p className="text-2xl" style={{ textAlign: "center" }}>
            {t("art_direction.sandra_martin.text")}
          </p>
        </div>

        {/* IMAGES */}
        <div className="images">
          {/* FULL */}
          <div className="row">
            <div className="col-full">
              <img src={ImageFull} alt="" />
            </div>
          </div>
          {/* END FULL */}
          {/* HALF */}
          <div className="row">
            <div className="col">
              <img src={ImageHalf1} alt="" />
            </div>
            <div className="col">
              <img src={ImageHalf2} alt="" />
            </div>
          </div>
          {/* END HALF */}
        </div>
        {/* END IMAGES */}
        <Link to="/art-direction" style={{ color: "inherit" }}>
          <h3 className="close-text text-4xl animated-cursor">
            {t("art_direction.back")}
          </h3>
        </Link>
      </div>
      <CustomCursor />
    </div>
  );
}
