import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import "../../../styles/components/project.scss";
import CustomCursor from "../../Cursor";
import { Link } from "react-router-dom";
import ImageFull1 from "../../../media/art-direction/pat/pat-full-1.png";
import ImageFull2 from "../../../media/art-direction/pat/pat-full-2.png";
import ImageFull3 from "../../../media/art-direction/pat/pat-full-3.png";
import ImageHalf1 from "../../../media/art-direction/pat/pat-half-1.png";
import ImageHalf2 from "../../../media/art-direction/pat/pat-half-2.png";

export default function Pat() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="project-focus spacing-block" style={{ paddingTop: "10vh" }}>
      <div className="content">
        <h2 className="title text-6xl">
          {t("art_direction.pat.project_name")}
        </h2>
        <h3 className="year text-4xl">2021</h3>
        {/* IMAGES */}
        <div className="images">
          {/* FULL */}
          <div className="row">
            <div className="col-full">
              <img src={ImageFull1} alt="" />
            </div>
          </div>
          {/* END FULL */}
        </div>
        {/* END IMAGES */}

        {/* DESCRIPTION */}
        {/* DESCRIPTION */}
        <div className="description">
          <h4 className="text-4xl">{t("art_direction.pat.title")}</h4>
          <h5 className="text-xl">{t("art_direction.pat.subtitle")}</h5>
          <p className="text-2xl">
            <Trans i18nKey="art_direction.pat.text">
              For my end-of-study project, I decided to design a communication
              aid for people suffering from autism spectrum disorders.
              <br />
              This mobile application offers a language-barrier-free medium
              where anyone can use and communicate with it in any way they wish.
              <br />
              The aim is to establish a relationship of trust between the person
              accompanying and those being accompanied. The companion is a
              person from the circle relationship. It can be a family member or
              a friend. family member or a friend. Here, everyone knows that
              they will be able to communicate communicate in the most
              appropriate way.
              <br />
              This application can be used as a one-off communication tool
              communication (in a crisis, for example), just as it can be the
              main channel for others. It's up to you to decide to what extent
              to what degree they wish the application to accompany their daily
              life.
            </Trans>
          </p>
        </div>

        {/* IMAGES */}
        <div className="images">
          {/* HALF */}
          <div className="row">
            <div className="col">
              <img src={ImageHalf1} alt="" />
            </div>
            <div className="col">
              <img src={ImageHalf2} alt="" />
            </div>
          </div>
          {/* END HALF */}
          {/* FULL */}
          <div className="row">
            <div className="col-full">
              <img src={ImageFull2} alt="" />
            </div>
          </div>
          {/* END FULL */}
          {/* FULL */}
          <div className="row">
            <div className="col-full">
              <img src={ImageFull3} alt="" />
            </div>
          </div>
          {/* END FULL */}
        </div>
        {/* END IMAGES */}
        <Link to="/art-direction" style={{ color: "inherit" }}>
          <h3 className="close-text text-4xl animated-cursor">
            {t("art_direction.back")}{" "}
          </h3>
        </Link>
      </div>
      <CustomCursor />
    </div>
  );
}
