import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../../styles/components/project.scss";
import CustomCursor from "../../Cursor";
import { Link } from "react-router-dom";
import Video from "../../../media/advertising/burger-king/Burger-King.mp4";
import ImageFull from "../../../media/advertising/burger-king/burger-king-full.png";

export default function BurgerKing() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="project-focus spacing-block" style={{ paddingTop: "10vh" }}>
      <div className="content">
        <h2 className="title text-6xl">Burger King</h2>
        <h3 className="year text-4xl">2023</h3>
        {/* VIDEO */}
        <video
          className="video"
          autoPlay
          muted
          loop
          controls
          src={Video}
        ></video>

        {/* DESCRIPTION */}
        <div className="description">
          <h4 className="text-4xl">{t("advertising.burger_king.title")}</h4>
          <p className="text-2xl">{t("advertising.burger_king.text")}</p>
        </div>

        {/* IMAGES */}
        <div className="images">
          {/* FULL */}
          <div className="row">
            <div className="col-full">
              <img src={ImageFull} alt="" />
            </div>
          </div>
          {/* END FULL */}
        </div>
        {/* END IMAGES */}
        <Link to="/advertising" style={{ color: "inherit" }}>
          <h3 className="close-text text-4xl animated-cursor">
            {t("advertising.back")}
          </h3>
        </Link>
      </div>
      <CustomCursor />
    </div>
  );
}
