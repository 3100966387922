import { gsap } from "gsap";
import { useTranslation } from "react-i18next";
// import "../i18n-work";
import "../styles/components/work.scss";
// import works from "../data/works.json";
import { Link } from "react-router-dom";
import ArtDirection1 from "../media/art-direction/pat/pat-miniature.png";
import ArtDirection2 from "../media/art-direction/loki/loki-miniature.png";
import ArtDirection3 from "../media/art-direction/jo-2024/jo-2024-miniature.png";
import Advertising1 from "../media/advertising/bottega-venetta/bottega-veneta_miniature.png";
import Advertising2 from "../media/advertising/burger-king/burger-king_miniature.png";
import Advertising3 from "../media/advertising/gautier/gautier-miniature.png";
import CreativeCoding1 from "../media/creative-coding/Reflexion/reflexion-miniature.png";
import CreativeCoding2 from "../media/creative-coding/Bubble/bubble-miniature.png";
import CreativeCoding3 from "../media/creative-coding/Snake/snake-miniature.png";
import WebDevelopment1 from "../media/web-development/Lengow/lengow_miniature.png";
import WebDevelopment2 from "../media/web-development/Bernard-arpage/bernard-arpage_miniature.png";
import WebDevelopment3 from "../media/web-development/Fluff/fluff-miniature.png";
import Painting1 from "../media/painting/22/n22-miniature.png";
import Painting2 from "../media/painting/16/n16-miniature.png";
import Painting3 from "../media/painting/5/n5-miniature.png";

export default function Work() {
  const { t } = useTranslation();
  const works = [
    {
      name: "my_work.art_direction",
      mediaPreview: [
        {
          media: ArtDirection1,
        },
        {
          media: ArtDirection2,
        },
        {
          media: ArtDirection3,
        },
      ],
      linkDiscoverMore: "/art-direction",
    },
    {
      name: "my_work.advertising",
      mediaPreview: [
        {
          media: Advertising1,
        },
        {
          media: Advertising2,
        },
        {
          media: Advertising3,
        },
      ],
      linkDiscoverMore: "/advertising",
    },
    {
      name: "my_work.creative_coding",
      mediaPreview: [
        {
          media: CreativeCoding1,
        },
        {
          media: CreativeCoding2,
        },
        {
          media: CreativeCoding3,
        },
      ],
      linkDiscoverMore: "/creative-coding",
    },
    {
      name: "my_work.web_development",
      mediaPreview: [
        {
          media: WebDevelopment1,
        },
        {
          media: WebDevelopment2,
        },
        {
          media: WebDevelopment3,
        },
      ],
      linkDiscoverMore: "/web-development",
    },
    {
      name: "my_work.painting",
      mediaPreview: [
        {
          media: Painting1,
        },
        {
          media: Painting2,
        },
        {
          media: Painting3,
        },
      ],
      linkDiscoverMore: "/painting",
    },
  ];

  const handleMouseEnter = (event) => {
    // console.log("ici => ", JSON.parse(event.target.dataset.info));
    const target = event.currentTarget;
    const myTitle = target.querySelector(".cell-title");
    const myImage = target.querySelectorAll(".img");

    gsap.killTweensOf([myImage, myTitle]);
    gsap
      .timeline()
      .addLabel("start", 0)
      .to(
        myImage,
        {
          duration: 0.4,
          ease: "power3",
          startAt: {
            scale: 0.8,
            xPercent: 20,
          },
          scale: 1,
          xPercent: 0,
          opacity: 1,
          stagger: -0.035,
        },
        "start"
      )
      .set(myTitle, { transformOrigin: "0% 50%" }, "start")
      .to(
        myTitle,
        {
          duration: 0.1,
          ease: "power1.in",
          yPercent: -100,
          onComplete: () => myTitle.classList.add("title-switch"),
        },
        "start"
      )
      .to(
        myTitle,
        {
          duration: 0.5,
          ease: "expo",
          startAt: {
            yPercent: 100,
            rotation: 15,
          },
          yPercent: 0,
          rotation: 0,
        },
        "start+=0.1"
      );
  };

  const handleMouseLeave = (event) => {
    const target = event.currentTarget;

    const myTitle = target.querySelector(".cell-title");
    const myImage = target.querySelectorAll(".img");

    gsap.killTweensOf([myImage, myTitle]);

    gsap
      .timeline()
      .addLabel("start")
      .to(
        myImage,
        {
          duration: 0.4,
          ease: "power4",
          opacity: 0,
          scale: 0.8,
        },
        "start"
      )
      .to(
        myTitle,
        {
          duration: 0.1,
          ease: "power1.in",
          yPercent: -100,
          onComplete: () => myTitle.classList.remove("title-switch"),
        },
        "start"
      )
      .to(
        myTitle,
        {
          duration: 0.5,
          ease: "expo",
          startAt: {
            yPercent: 100,
            rotation: 15,
          },
          yPercent: 0,
          rotation: 0,
        },
        "start+=0.1"
      );
  };

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="work">
      <h2 className="text-6xl spacing-block">{t("my_work.title")} </h2>
      <section className="content">
        {works.map((work, index) => (
          <Link
            onClick={handleClick}
            className="row animated-cursor"
            key={index}
            to={work.linkDiscoverMore}
            data-info={JSON.stringify(work)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="cell cell-text spacing-block">
              <h2 className="cell-title text-4xl oh">
                {/* <span className="oh__inner">{work.name}</span> */}
                <span className="oh__inner">{t(work.name)}</span>
              </h2>
            </div>
            <div className="cell cell-images spacing-block spacing-block">
              {work.mediaPreview.map((item, index) => (
                <div className="img">
                  <div
                    className="img-inner"
                    style={{ backgroundImage: `url(${item.media})` }}
                  ></div>
                </div>
              ))}
            </div>
          </Link>
        ))}
      </section>
    </div>
  );
}
