import CV from "../media/cv.png";
import "../styles/components/cv.scss";

export default function Cv() {
  return (
    <div className="window cv">
      <img src={CV} alt="Cv" />
    </div>
  );
}
