import "../styles/components/introduction.scss";
import { useTranslation, Trans } from "react-i18next";

export default function Introduction() {
  const { t } = useTranslation();
  return (
    <div className="introduction spacing-block">
      <div className="top-content">
        {/* <h2 className="text-8xl">Hello 👋</h2> */}
        <h2 className="text-8xl">{t("introduction.hello")}</h2>
        <p className="text-4xl">
          <Trans i18nKey="introduction.text_1">
            I'm a French <b>Digital Designer</b> based in Nantes.
          </Trans>
        </p>
        {/* <p className="text-4xl">
          I'm a French <b>Digital Designer</b> based in Nantes.
        </p> */}
        <p className="text-4xl">
          <Trans i18nKey="introduction.text_2">
            Focus on the <b>design</b> (product and advertising), the
            <b>marketing</b> and the <b>web-development</b>, I have a large
            vision and understanding in a project.
          </Trans>
        </p>
      </div>
      <div className="bottom-content">
        {/* <div className="ornaments text-8xl">ImOpenToWork</div> */}
        <div className="text text-3xl">
          <Trans i18nKey="introduction.text_3">
            Today I'm <b>open to new challenge & new opportunities</b> in Nantes
            or anywhere with remote possibility.
          </Trans>
        </div>
      </div>
    </div>
  );
}
