import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import "../../../styles/components/project.scss";
import CustomCursor from "../../Cursor";
import { Link } from "react-router-dom";
import Video from "../../../media/advertising/cupra/Cupra.mp4";
import ImageFull1 from "../../../media/advertising/cupra/cupra-full-1.png";
import ImageFull2 from "../../../media/advertising/cupra/cupra-full-2.png";
import ImageHalf1 from "../../../media/advertising/cupra/cupra-half-1.png";
import ImageHalf2 from "../../../media/advertising/cupra/cupra-half-2.png";

export default function Cupra() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="project-focus spacing-block" style={{ paddingTop: "10vh" }}>
      <div className="content">
        <h2 className="title text-6xl">Cupra</h2>
        <h3 className="year text-4xl">2023</h3>
        {/* VIDEO */}
        <video
          className="video"
          autoPlay
          muted
          loop
          controls
          src={Video}
        ></video>

        {/* DESCRIPTION */}
        <div className="description">
          <h4 className="text-4xl">{t("advertising.cupra.title")}</h4>
          <p className="text-2xl">
            <Trans i18nKey="advertising.cupra.text">
              For redirection to the website, an interactive format where the
              user can click to discover a few specifications of a new car
              (Cupra Formentor) and then click to discover more on the website.
              <br />
              For redirection to the nearest store, a format with a video at the
              top and text below more clearly defines the message and the
              vehicle presented (Cupra Leon). Then a button to redirect to a map
              showing the nearest store.
            </Trans>
          </p>
        </div>

        {/* IMAGES */}
        <div className="images">
          {/* FULL */}
          <div className="row">
            <div className="col-full">
              <img src={ImageFull1} alt="" />
            </div>
          </div>
          {/* END FULL */}
          {/* HALF */}
          <div className="row">
            <div className="col">
              <img src={ImageHalf1} alt="" />
            </div>
            <div className="col">
              <img src={ImageHalf2} alt="" />
            </div>
          </div>
          {/* END HALF */}
          {/* FULL */}
          <div className="row">
            <div className="col-full">
              <img src={ImageFull2} alt="" />
            </div>
          </div>
          {/* END FULL */}
        </div>
        {/* END IMAGES */}
        <Link to="/advertising" style={{ color: "inherit" }}>
          <h3 className="close-text text-4xl animated-cursor">
            {t("advertising.back")}
          </h3>
        </Link>
      </div>
      <CustomCursor />
    </div>
  );
}
