import { useTranslation } from "react-i18next";
import "../styles/components/header.scss";
import Portrait from "../media/portrait-full.png";

export default function Header({ headerScrollText, headerBlock, image }) {
  const { t } = useTranslation();
  return (
    <header className="header" ref={headerBlock}>
      <h1
        className="text-9xl"
        data-scroll
        data-scroll-speed="2"
        data-scroll-position="
          top"
      >
        Benjamin Arpage
      </h1>
      <h3
        className="text-3xl"
        data-scroll
        data-scroll-speed="3"
        data-scroll-position="top"
      >
        {t("header.text")}
      </h3>
      <div
        data-scroll
        data-scroll-speed="4"
        data-scroll-position="top"
        ref={headerScrollText}
      >
        <h4 className="text-xl">{t("header.scroll")}</h4>
        <div className="arrow">
          <svg width="64" height="51" xmlns="http://www.w3.org/2000/svg">
            <g stroke="#361fdb" fill="none" fillRule="evenodd">
              <path
                strokeLinecap="square"
                d="m55.766 32.528-5.125-1.865M52.677 36.938l3.1-4.618"
              />
              <path d="M8.773 10.587S-.693 49.485 56.13 32.3" />
            </g>
          </svg>
        </div>
      </div>
      <div className="image-block">
        <img src={Portrait} alt="" ref={image} />
      </div>
    </header>
  );
}
