import { useState } from "react";
import { useTranslation } from "react-i18next";
import "../styles/components/skills.scss";

export default function Skills() {
  const { t } = useTranslation();
  const [openWebdesignDevelopment, setOpenWebdesignDevelopment] =
    useState(false);
  const [openDesignResearch, setOpenDesignResearch] = useState(false);
  const [openMarketingStrategy, setOpenMarketingStrategy] = useState(false);
  const [openManagementSupport, setOpenManagementSupport] = useState(false);

  const handleOpenWebdesignDevelopment = () => {
    setOpenWebdesignDevelopment(true);
  };

  const handleCloseWebdesignDevelopment = () => {
    setOpenWebdesignDevelopment(false);
  };

  const handleOpenDesignResearch = () => {
    setOpenDesignResearch(true);
  };

  const handleCloseDesignResearch = () => {
    setOpenDesignResearch(false);
  };

  const handleOpenMarketingStategy = () => {
    setOpenMarketingStrategy(true);
  };

  const handleCloseMarketingStategy = () => {
    setOpenMarketingStrategy(false);
  };

  const handleOpenManagementSupport = () => {
    setOpenManagementSupport(true);
  };

  const handleCloseManagementSupport = () => {
    setOpenManagementSupport(false);
  };
  return (
    <div className="skills spacing-block">
      <h2 className="text-6xl">{t("skills.title")}</h2>
      <div className="content">
        <div className="col">
          <div className="line">
            <div className="head">
              <h3 className="title text-4xl">
                {t("skills.webdesign_development.title")}
              </h3>
              {!openWebdesignDevelopment ? (
                <div
                  className="indicator indicator-close text-5xl animated-cursor"
                  onClick={handleOpenWebdesignDevelopment}
                >
                  +
                </div>
              ) : (
                <div
                  className="indicator indicator-open text-5xl animated-cursor"
                  onClick={handleCloseWebdesignDevelopment}
                >
                  -
                </div>
              )}
            </div>
            {openWebdesignDevelopment ? (
              <ul className="detail">
                <li className="text-xl">
                  {t("skills.webdesign_development.web_mobile_design")}
                </li>
                <li className="text-xl">
                  {t("skills.webdesign_development.front_end_development")}
                </li>
                <li className="text-xl">
                  {t("skills.webdesign_development.mobile_development")}
                </li>
              </ul>
            ) : (
              <></>
            )}
          </div>
          <div className="line">
            <div className="head">
              <h3 className="title text-4xl">
                {t("skills.design_research.title")}
              </h3>
              {!openDesignResearch ? (
                <div
                  className="indicator indicator-close text-5xl animated-cursor"
                  onClick={handleOpenDesignResearch}
                >
                  +
                </div>
              ) : (
                <div
                  className="indicator indicator-open text-5xl animated-cursor"
                  onClick={handleCloseDesignResearch}
                >
                  -
                </div>
              )}
            </div>
            {openDesignResearch ? (
              <ul className="detail">
                <li className="text-xl">
                  {t("skills.design_research.accessibility")}
                </li>
                <li className="text-xl">
                  {t("skills.design_research.advertising_design")}
                </li>
                <li className="text-xl">
                  {t("skills.design_research.brand_design_communication")}
                </li>
                <li className="text-xl">
                  {t("skills.design_research.design_system_atomic_design")}
                </li>
                <li className="text-xl">
                  {t("skills.design_research.graphic_design")}
                </li>
                <li className="text-xl">
                  {t("skills.design_research.motion_design")}
                </li>
                <li className="text-xl">
                  {t("skills.design_research.product_design")}
                </li>
                <li className="text-xl">
                  {t("skills.design_research.rd_innovation")}
                </li>
                <li className="text-xl">
                  {t("skills.design_research.ux_research")}
                </li>
              </ul>
            ) : (
              <></>
            )}
          </div>
          <div className="line">
            <div className="head">
              <h3 className="title text-4xl">
                {t("skills.marketing_strategy.title")}
              </h3>
              {!openMarketingStrategy ? (
                <div
                  className="indicator indicator-close text-5xl animated-cursor"
                  onClick={handleOpenMarketingStategy}
                >
                  +
                </div>
              ) : (
                <div
                  className="indicator indicator-open text-5xl animated-cursor"
                  onClick={handleCloseMarketingStategy}
                >
                  -
                </div>
              )}
            </div>
            {openMarketingStrategy ? (
              <ul className="detail">
                <li className="text-xl">
                  {t("skills.marketing_strategy.audit")}
                </li>
                <li className="text-xl">
                  {t("skills.marketing_strategy.benchmark_analyse")}
                </li>
                <li className="text-xl">
                  {t("skills.marketing_strategy.design_strategy")}
                </li>
                <li className="text-xl">
                  {t("skills.marketing_strategy.digital_campaign")}
                </li>
                <li className="text-xl">
                  {t("skills.marketing_strategy.seo_sea")}
                </li>
                <li className="text-xl">
                  {t("skills.marketing_strategy.social_media")}
                </li>
              </ul>
            ) : (
              <></>
            )}
          </div>
          <div className="line">
            <div className="head">
              <h3 className="title text-4xl">
                {t("skills.management_organisation_support.title")}
              </h3>
              {!openManagementSupport ? (
                <div
                  className="indicator indicator-close text-5xl animated-cursor"
                  onClick={handleOpenManagementSupport}
                >
                  +
                </div>
              ) : (
                <div
                  className="indicator indicator-open text-5xl animated-cursor"
                  onClick={handleCloseManagementSupport}
                >
                  -
                </div>
              )}
            </div>
            {openManagementSupport ? (
              <ul className="detail">
                <li className="text-xl">
                  {t(
                    "skills.management_organisation_support.creative_art_direction"
                  )}
                </li>
                <li className="text-xl">
                  {t(
                    "skills.management_organisation_support.mentoring_support"
                  )}
                </li>
                <li className="text-xl">
                  {t(
                    "skills.management_organisation_support.project_management"
                  )}
                </li>
                <li className="text-xl">
                  {t("skills.management_organisation_support.team_leadership")}
                </li>
              </ul>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
