import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../../styles/components/project.scss";
import CustomCursor from "../../../components/Cursor";
import { Link } from "react-router-dom";
import Image from "../../../media/painting/18/n18-full.png";

export default function FreeBirds() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="project-focus spacing-block" style={{ paddingTop: "10vh" }}>
      <div className="content">
        <h2 className="title text-6xl">Free Birds</h2>
        <h3 className="year text-4xl">2022</h3>

        {/* DESCRIPTION */}
        <div className="description">
          <h4 className="text-4xl">{t("painting.free_birds.title")}</h4>
          <p className="text-2xl" style={{ textAlign: "center" }}>
            {t("painting.free_birds.text")}
          </p>
        </div>

        {/* IMAGES */}
        <div className="images">
          {/* FULL */}
          <div className="row">
            <div className="col-full">
              <img src={Image} alt="first birds" />
            </div>
          </div>
          {/* END FULL */}
        </div>
        {/* END IMAGES */}
        <Link to="/painting" style={{ color: "inherit" }}>
          <h3 className="close-text text-4xl animated-cursor">
            {t("painting.back")}
          </h3>
        </Link>
      </div>
      <CustomCursor />
    </div>
  );
}
