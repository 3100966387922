import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { SpeedInsights } from "@vercel/speed-insights/react";
import "./styles/global.scss";
import Home from "./routes/home";
import Advertising from "./routes/advertising";
import ArtDirection from "./routes/artDirection";
import Painting from "./routes/painting";
import WebDevelopment from "./routes/webDevelopment";
import CreativeCoding from "./routes/creativeCoding";
import Credits from "./routes/credits";
import MenuComponent from "./components/Menu";
import Footer from "./components/Footer";
import LostFlowers from "./components/projects/painting/23";
import UltraViolet from "./components/projects/painting/22";
import BrokenHeart from "./components/projects/painting/21";
import UnderPressure from "./components/projects/painting/20";
import TrunkOfTheNightmare from "./components/projects/painting/19";
import FreeBirds from "./components/projects/painting/18";
import StrollInTheSpirit from "./components/projects/painting/17";
import BlueDreamInACage from "./components/projects/painting/16";
import GroundAfterRain from "./components/projects/painting/15";
import WrongWay from "./components/projects/painting/14";
import DivingIntoTheDepths from "./components/projects/painting/13";
import LookAtme from "./components/projects/painting/11";
import Disillusion from "./components/projects/painting/5";
import BernardArpage from "./components/projects/web-development/bernard-arpage";
import Fluff from "./components/projects/web-development/fluff";
import Lengow from "./components/projects/web-development/lengow";
import Bubble from "./components/projects/creative-coding/bubble";
import Reflexion from "./components/projects/creative-coding/reflexion";
import Sculpture from "./components/projects/creative-coding/sculpture";
import Snake from "./components/projects/creative-coding/snake";
import Triangle from "./components/projects/creative-coding/triangle";
import Wave from "./components/projects/creative-coding/wave";
import Wind from "./components/projects/creative-coding/wind";
import AbcTerroirs from "./components/projects/art-direction/abc-terroirs";
import Coquelicot from "./components/projects/art-direction/coquelicot";
import Jo2024 from "./components/projects/art-direction/jo-2024";
import LesPaniersFermiers from "./components/projects/art-direction/les-paniers-fermiers";
import Loki from "./components/projects/art-direction/loki";
import Mizu from "./components/projects/art-direction/mizu";
import Moyotia from "./components/projects/art-direction/moyotia";
import Pat from "./components/projects/art-direction/pat";
import SandraMartin from "./components/projects/art-direction/sandra-martin";
import Uniqlo from "./components/projects/art-direction/uniqlo";
import Accuvue from "./components/projects/advertising/accuvue";
import BottegaVeneta from "./components/projects/advertising/bottega-venetta";
import BurgerKing from "./components/projects/advertising/burger-king";
import Canon from "./components/projects/advertising/canon";
import Cupra from "./components/projects/advertising/cupra";
import Gautier from "./components/projects/advertising/gautier";
import LPPromotion from "./components/projects/advertising/lp-promotion";
import Rains from "./components/projects/advertising/rains";
import RangeRover from "./components/projects/advertising/range-rover";
import Reebok from "./components/projects/advertising/rebook";
import Starbucks from "./components/projects/advertising/starbucks";
import Cv from "./routes/cv";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <MenuComponent />
        <LocationProvider>
          <RoutesWithAnimation />
        </LocationProvider>
        <Footer />
        <SpeedInsights />
      </BrowserRouter>
    </div>
  );
}

function LocationProvider({ children }) {
  return <AnimatePresence>{children}</AnimatePresence>;
}

function RoutesWithAnimation() {
  const location = useLocation();

  return (
    <Routes location={location} key={location.key}>
      <Route path="/" element={<Home />} />
      <Route path="/cv" element={<Cv />} />
      <Route path="/art-direction" element={<ArtDirection />} />
      <Route path="/advertising" element={<Advertising />} />
      <Route path="/creative-coding" element={<CreativeCoding />} />
      <Route path="/web-development" element={<WebDevelopment />} />
      <Route path="/painting" element={<Painting />} />
      <Route path="/credits" element={<Credits />} />
      <Route path="/painting/lost-flowers" element={<LostFlowers />} />
      <Route path="/painting/ultra-violet" element={<UltraViolet />} />
      <Route path="/painting/broken-heart" element={<BrokenHeart />} />
      <Route path="/painting/under-pressure" element={<UnderPressure />} />
      <Route
        path="/painting/trunk-of-the-nightmare"
        element={<TrunkOfTheNightmare />}
      />
      <Route path="/painting/free-birds" element={<FreeBirds />} />
      <Route
        path="/painting/stroll-in-the-spirit"
        element={<StrollInTheSpirit />}
      />
      <Route
        path="/painting/blue-dream-in-a-cage"
        element={<BlueDreamInACage />}
      />
      <Route path="/painting/ground-after-rain" element={<GroundAfterRain />} />
      <Route path="/painting/wrong-way" element={<WrongWay />} />
      <Route
        path="/painting/diving-into-the-depths"
        element={<DivingIntoTheDepths />}
      />
      <Route path="/painting/look-at-me" element={<LookAtme />} />
      <Route path="/painting/disillusion" element={<Disillusion />} />
      <Route
        path="/web-development/bernard-arpage-formation"
        element={<BernardArpage />}
      />
      <Route path="/web-development/fluff" element={<Fluff />} />
      <Route path="/web-development/lengow" element={<Lengow />} />
      <Route path="/creative-coding/bubble" element={<Bubble />} />
      <Route path="/creative-coding/reflexion" element={<Reflexion />} />
      <Route path="/creative-coding/sulpture" element={<Sculpture />} />
      <Route path="/creative-coding/snake" element={<Snake />} />
      <Route path="/creative-coding/triangle" element={<Triangle />} />
      <Route path="/creative-coding/wave" element={<Wave />} />
      <Route path="/creative-coding/wind" element={<Wind />} />
      <Route path="/art-direction/abc-terroirs" element={<AbcTerroirs />} />
      <Route path="/art-direction/coquelicot" element={<Coquelicot />} />
      <Route path="/art-direction/jo-2024" element={<Jo2024 />} />
      <Route
        path="/art-direction/les-paniers-fermiers"
        element={<LesPaniersFermiers />}
      />
      <Route path="/art-direction/loki" element={<Loki />} />
      <Route path="/art-direction/mizu" element={<Mizu />} />
      <Route path="/art-direction/moyotia" element={<Moyotia />} />
      <Route path="/art-direction/pat" element={<Pat />} />
      <Route path="/art-direction/sandra-martin" element={<SandraMartin />} />
      <Route path="/art-direction/uniqlo" element={<Uniqlo />} />
      <Route path="/advertising/accuvue" element={<Accuvue />} />
      <Route path="/advertising/bottega-veneta" element={<BottegaVeneta />} />
      <Route path="/advertising/burger-king" element={<BurgerKing />} />
      <Route path="/advertising/canon" element={<Canon />} />
      <Route path="/advertising/cupra" element={<Cupra />} />
      <Route path="/advertising/gautier" element={<Gautier />} />
      <Route path="/advertising/lp-promotion" element={<LPPromotion />} />
      <Route path="/advertising/rains" element={<Rains />} />
      <Route path="/advertising/range-rover" element={<RangeRover />} />
      <Route path="/advertising/reebok" element={<Reebok />} />
      <Route path="/advertising/starbucks" element={<Starbucks />} />
    </Routes>
  );
}
export default App;
