import { createRef, useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import Header from "../components/Header";
import Introduction from "../components/Introduction";
import LastPosition from "../components/LastPosition";
import Work from "../components/Work";
import Skills from "../components/Skills";
import CustomCursor from "../components/Cursor";
import Clients from "../components/Clients";

export default function Home() {
  const scrollRef = createRef();
  const headerBlockRef = useRef();
  const headerScrollTextRef = useRef();
  const imageRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    gsap.registerPlugin(ScrollTrigger);
    gsap.to(imageRef.current, {
      width: "98%",
      scrollTrigger: {
        trigger: headerScrollTextRef.current,
        start: "top 50%",
        end: "top 0%",
        scrub: 3,
      },
    });
    gsap.to(headerBlockRef.current, {
      backgroundColor: "#361fdb",
      scrollTrigger: {
        scrub: 3,
        trigger: imageRef.current,
        sroller: headerBlockRef.current,
        start: "0% 50%",
        end: "25% 50%",
      },
    });
  });

  return (
    <div className="window" ref={scrollRef}>
      <Header
        headerScrollText={headerScrollTextRef}
        image={imageRef}
        headerBlock={headerBlockRef}
      />
      <Introduction />
      <LastPosition />
      <Work />
      <Skills />
      <Clients />
      {/* <div style={{ marginBottom: "50px" }}></div> */}
      <CustomCursor />
    </div>
  );
}
