import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomCursor from "../components/Cursor";
import ProjectMiniature from "../components/ProjectMiniature";
import "../styles/components/page-projects.scss";
import BernardArpage from "../media/web-development/Bernard-arpage/bernard-arpage_miniature.png";
import Fluff from "../media/web-development/Fluff/fluff-miniature.png";
import Lengow from "../media/web-development/Lengow/lengow_miniature.png";

export default function WebDevelopment() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="window" style={{ paddingTop: "20vh" }}>
      <div className="project spacing-block">
        <h1 className="page-title text-8xl">{t("web_development.title")}</h1>
        <div className="project-elements">
          <ProjectMiniature image={Lengow} name="Lengow" link="lengow" />
          <ProjectMiniature
            image={BernardArpage}
            name="Bernard Arpage Formation"
            link="bernard-arpage-formation"
          />
          <ProjectMiniature image={Fluff} name="Fluff" link="fluff" />
        </div>
      </div>
      <CustomCursor />
    </div>
  );
}
