import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../styles/components/footer.scss";

export default function Footer() {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <h2 className="title text-9xl">Contact</h2>
      <a
        href="mailto:benjaminarpage@gmail.com"
        className="mail text-4xl animated-cursor"
      >
        benjaminarpage@gmail.com
      </a>
      <div className="links text-2xl">
        <a
          href="https://www.linkedin.com/in/benjamin-arpage"
          className="animated-cursor"
          rel="noreferrer"
          target="_blank"
        >
          LinkedIn
        </a>
        <a
          href="https://www.instagram.com/moijesuisben_art/"
          className="animated-cursor"
          rel="noreferrer"
          target="_blank"
        >
          Instagram
        </a>
        <Link to="/credits" className="animated-cursor">
          {t("footer.credits")}
        </Link>
      </div>
      <p className="credits text-xl">{t("footer.mention")}</p>
    </div>
  );
}
