import { useState } from "react";
import { useTranslation } from "react-i18next";
import "../styles/components/clients.scss";

export default function Clients() {
  const { t } = useTranslation();
  const [openAutomotive, setOpenAutomotive] = useState(false);
  const [openMode, setOpenMode] = useState(false);
  const [openFood, setOpenFood] = useState(false);
  const [openHome, setOpenHome] = useState(false);
  const [openHealth, setOpenHealth] = useState(false);
  const [openOther, setOpenOther] = useState(false);

  const handleOpenAutomotive = () => {
    setOpenAutomotive(true);
  };

  const handleCloseAutomotive = () => {
    setOpenAutomotive(false);
  };

  const handleOpenMode = () => {
    setOpenMode(true);
  };

  const handleCloseMode = () => {
    setOpenMode(false);
  };

  const handleOpenFood = () => {
    setOpenFood(true);
  };

  const handleCloseFood = () => {
    setOpenFood(false);
  };

  const handleOpenHome = () => {
    setOpenHome(true);
  };

  const handleCloseHome = () => {
    setOpenHome(false);
  };

  const handleOpenHealth = () => {
    setOpenHealth(true);
  };

  const handleCloseHealth = () => {
    setOpenHealth(false);
  };

  const handleOpenOther = () => {
    setOpenOther(true);
  };

  const handleCloseOther = () => {
    setOpenOther(false);
  };

  return (
    <div className="clients spacing-block">
      <h2 className="text-6xl">{t("clients.title")}</h2>
      <div className="content">
        <div className="col">
          <div className="line">
            <div className="head">
              <h3 className="title text-4xl">{t("clients.automotive")}</h3>
              {!openAutomotive ? (
                <div
                  className="indicator indicator-close text-5xl animated-cursor"
                  onClick={handleOpenAutomotive}
                >
                  +
                </div>
              ) : (
                <div
                  className="indicator indicator-open text-5xl animated-cursor"
                  onClick={handleCloseAutomotive}
                >
                  -
                </div>
              )}
            </div>
            {openAutomotive ? (
              <ul className="detail">
                <li className="text-xl">Audi</li>
                <li className="text-xl">Cupra</li>
                <li className="text-xl">Ford</li>
                <li className="text-xl">Mini</li>
                <li className="text-xl">Peugeot</li>
                <li className="text-xl">Porsche</li>
                <li className="text-xl">Range Rover</li>
                <li className="text-xl">Sixt</li>
                <li className="text-xl">Skoda</li>
                <li className="text-xl">Toyota</li>
              </ul>
            ) : (
              <></>
            )}
          </div>
          <div className="line">
            <div className="head">
              <h3 className="title text-4xl">
                {t("clients.mode_fashion_luxury")}
              </h3>
              {!openMode ? (
                <div
                  className="indicator indicator-close text-5xl animated-cursor"
                  onClick={handleOpenMode}
                >
                  +
                </div>
              ) : (
                <div
                  className="indicator indicator-open text-5xl animated-cursor"
                  onClick={handleCloseMode}
                >
                  -
                </div>
              )}
            </div>
            {openMode ? (
              <ul className="detail">
                <li className="text-xl">Bottega Venetta</li>
                <li className="text-xl">C&A</li>
                <li className="text-xl">Carolina Herrera</li>
                <li className="text-xl">Chanel</li>
                <li className="text-xl">Galeries Lafayette</li>
                <li className="text-xl">Nocibé</li>
                <li className="text-xl">Pandora</li>
                <li className="text-xl">Reebok</li>
                <li className="text-xl">Takko</li>
              </ul>
            ) : (
              <></>
            )}
          </div>
          <div className="line">
            <div className="head">
              <h3 className="title text-4xl">{t("clients.food")}</h3>
              {!openFood ? (
                <div
                  className="indicator indicator-close text-5xl animated-cursor"
                  onClick={handleOpenFood}
                >
                  +
                </div>
              ) : (
                <div
                  className="indicator indicator-open text-5xl animated-cursor"
                  onClick={handleCloseFood}
                >
                  -
                </div>
              )}
            </div>
            {openFood ? (
              <ul className="detail">
                <li className="text-xl">Au Bureau</li>
                <li className="text-xl">Auchan</li>
                <li className="text-xl">Buffalo Grill</li>
                <li className="text-xl">Delonghi</li>
                <li className="text-xl">Grand Frais</li>
                <li className="text-xl">Intermarché</li>
                <li className="text-xl">Leclerc</li>
                <li className="text-xl">Monoprix</li>
                <li className="text-xl">Netto</li>
                <li className="text-xl">Pizza Hut</li>
                <li className="text-xl">Picard</li>
                <li className="text-xl">Starbucks</li>
              </ul>
            ) : (
              <></>
            )}
          </div>
          <div className="line">
            <div className="head">
              <h3 className="title text-4xl">
                {t("clients.home_amenagement")}
              </h3>
              {!openHome ? (
                <div
                  className="indicator indicator-close text-5xl animated-cursor"
                  onClick={handleOpenHome}
                >
                  +
                </div>
              ) : (
                <div
                  className="indicator indicator-open text-5xl animated-cursor"
                  onClick={handleCloseHome}
                >
                  -
                </div>
              )}
            </div>
            {openHome ? (
              <ul className="detail">
                <li className="text-xl">Bosch</li>
                <li className="text-xl">Brico Dépôt</li>
                <li className="text-xl">Canon</li>
                <li className="text-xl">Casa</li>
                <li className="text-xl">Conforama</li>
                <li className="text-xl">Dr Beickmann</li>
                <li className="text-xl">Dyson</li>
                <li className="text-xl">Gautier</li>
                <li className="text-xl">Lapyere</li>
                <li className="text-xl">Samsung</li>
                <li className="text-xl">Vie & Véranda</li>
              </ul>
            ) : (
              <></>
            )}
          </div>
          <div className="line">
            <div className="head">
              <h3 className="title text-4xl">{t("clients.health_care")}</h3>
              {!openHealth ? (
                <div
                  className="indicator indicator-close text-5xl animated-cursor"
                  onClick={handleOpenHealth}
                >
                  +
                </div>
              ) : (
                <div
                  className="indicator indicator-open text-5xl animated-cursor"
                  onClick={handleCloseHealth}
                >
                  -
                </div>
              )}
            </div>
            {openHealth ? (
              <ul className="detail">
                <li className="text-xl">Acuvue</li>
                <li className="text-xl">EFS</li>
                <li className="text-xl">Medicis</li>
              </ul>
            ) : (
              <></>
            )}
          </div>
          <div className="line">
            <div className="head">
              <h3 className="title text-4xl">{t("clients.other")}</h3>
              {!openOther ? (
                <div
                  className="indicator indicator-close text-5xl animated-cursor"
                  onClick={handleOpenOther}
                >
                  +
                </div>
              ) : (
                <div
                  className="indicator indicator-open text-5xl animated-cursor"
                  onClick={handleCloseOther}
                >
                  -
                </div>
              )}
            </div>
            {openOther ? (
              <ul className="detail">
                <li className="text-xl">American Express</li>
                <li className="text-xl">Becton Dickinson</li>
                <li className="text-xl">Communauté Urbaine de Dunkerque</li>
                <li className="text-xl">Edflex</li>
                <li className="text-xl">Groupe Casino Arevian</li>
                <li className="text-xl">LP Promotion</li>
                <li className="text-xl">Lengow</li>
                <li className="text-xl">Locala</li>
                <li className="text-xl">MHSC</li>
                <li className="text-xl">Naoned</li>
                <li className="text-xl">Océarium du Croisic</li>
                <li className="text-xl">SNCF</li>
              </ul>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
