import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomCursor from "../components/Cursor";
import ProjectMiniature from "../components/ProjectMiniature";
import "../styles/components/page-projects.scss";
import AbcTerroirs from "../media/art-direction/abc-terroirs/abc-terroirs-miniature.png";
import Coquelicot from "../media/art-direction/coquelicot/coquelicot-miniature.png";
import Jo2024 from "../media/art-direction/jo-2024/jo-2024-miniature.png";
import LesPaniersFermiers from "../media/art-direction/les-paniers-fermiers/les-paniers-fermier-miniature.png";
import Loki from "../media/art-direction/loki/loki-miniature.png";
import Mizu from "../media/art-direction/mizu/mizu-miniature.png";
import Moyotia from "../media/art-direction/moyotia/moyotia-miniature.png";
import Pat from "../media/art-direction/pat/pat-miniature.png";
import SandraMartin from "../media/art-direction/sandra-martin/sandra-martin-miniature.png";
import Uniqlo from "../media/art-direction/uniqlo/uniqlo-miniature.png";

export default function ArtDirection() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="window" style={{ paddingTop: "20vh" }}>
      <div className="project spacing-block">
        <h1 className="page-title text-8xl">{t("art_direction.title")}</h1>
        <div className="project-elements">
          <ProjectMiniature
            image={Pat}
            name={t("art_direction.pat.project_name")}
            link="pat"
          />
          <ProjectMiniature
            image={Loki}
            name={t("art_direction.loki.project_name")}
            link="loki"
          />
          <ProjectMiniature
            image={Jo2024}
            name={t("art_direction.jo2024.project_name")}
            link="jo-2024"
          />

          <ProjectMiniature
            image={AbcTerroirs}
            name={t("art_direction.abc_terroirs.project_name")}
            link="abc-terroirs"
          />
          <ProjectMiniature
            image={LesPaniersFermiers}
            name={t("art_direction.les_paniers_fermiers.project_name")}
            link="les-paniers-fermiers"
          />
          <ProjectMiniature
            image={Mizu}
            name={t("art_direction.mizu.project_name")}
            link="mizu"
          />
          <ProjectMiniature
            image={Moyotia}
            name={t("art_direction.moyotia.project_name")}
            link="moyotia"
          />
          <ProjectMiniature
            image={SandraMartin}
            name={t("art_direction.sandra_martin.project_name")}
            link="sandra-martin"
          />
          <ProjectMiniature
            image={Uniqlo}
            name={t("art_direction.uniqlo.project_name")}
            link="uniqlo"
          />
          <ProjectMiniature
            image={Coquelicot}
            name={t("art_direction.coquelicot.project_name")}
            link="coquelicot"
          />
        </div>
      </div>
      <CustomCursor />
    </div>
  );
}
