import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomCursor from "../components/Cursor";
import ProjectMiniature from "../components/ProjectMiniature";
import "../styles/components/page-projects.scss";
import Accuvue from "../media/advertising/accuvue/accuvue_miniature.png";
import BottegaVeneta from "../media/advertising/bottega-venetta/bottega-veneta_miniature.png";
import BurgerKing from "../media/advertising/burger-king/burger-king_miniature.png";
import Canon from "../media/advertising/canon/canon-miniature.png";
import Cupra from "../media/advertising/cupra/cupra-miniature.png";
import Gautier from "../media/advertising/gautier/gautier-miniature.png";
import LPPromotion from "../media/advertising/lp-promotion/lp-promotion_miniature.png";
import Rains from "../media/advertising/rains/rains_miniature.png";
import RangeRover from "../media/advertising/ranger-rover/range-rover-miniature.png";
import Reebok from "../media/advertising/rebook/rebook_miniature.png";
import Starbucks from "../media/advertising/starbucks/starbucks_miniature.png";

export default function Advertising() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="window" style={{ paddingTop: "20vh" }}>
      <div className="project spacing-block">
        <h1 className="page-title text-8xl">{t("advertising.title")}</h1>
        <div className="project-elements">
          <ProjectMiniature
            image={BottegaVeneta}
            name="Bottega Veneta"
            link="bottega-veneta"
          />
          <ProjectMiniature image={Cupra} name="Cupra" link="cupra" />
          <ProjectMiniature
            image={RangeRover}
            name="Range Rover"
            link="range-rover"
          />
          <ProjectMiniature
            image={BurgerKing}
            name="Burger King"
            link="burger-king"
          />
          <ProjectMiniature image={Rains} name="Rains" link="rains" />
          <ProjectMiniature image={Gautier} name="Gautier" link="gautier" />
          <ProjectMiniature image={Accuvue} name="Accuvue" link="accuvue" />
          <ProjectMiniature
            image={LPPromotion}
            name="LP Promotion"
            link="lp-promotion"
          />
          <ProjectMiniature image={Canon} name="Canon" link="canon" />
          <ProjectMiniature image={Reebok} name="Reebok" link="reebok" />
          <ProjectMiniature
            image={Starbucks}
            name="Starbucks"
            link="starbucks"
          />
        </div>
      </div>
      <CustomCursor />
    </div>
  );
}
