import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../styles/components/credits.scss";
import CustomCursor from "../components/Cursor";
export default function Credits() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="window" style={{ paddingTop: "20vh" }}>
      <div className="credits spacing-block">
        {/* <p className="text-2xl">{t("credits.text_1")}</p> */}
        <p className="text-2xl">{t("credits.text_2")}</p>
        <ul>
          <li className="text-2xl">
            {t("credits.art_direction")} (
            <Link
              className="animated-cursor"
              to="https://www.linkedin.com/in/lucile-moutot-1475581b8/"
              target="_blank"
            >
              LinkedIn
            </Link>
            )
          </li>
          <li className="text-2xl">{t("credits.development")}</li>
          <li className="text-2xl">
            Photo: Lucile Moutot (
            <Link
              className="animated-cursor"
              to="https://www.linkedin.com/in/lucile-moutot-1475581b8/"
              target="_blank"
            >
              LinkedIn
            </Link>
            )
          </li>
          <li className="text-2xl">
            {t("credits.font_principal")} (
            <Link
              className="animated-cursor"
              to="https://www.behance.net/gallery/100653669/Erotique-Typeface"
              target="_blank"
            >
              {t("credits.link")}
            </Link>
            )
          </li>
          <li className="text-2xl">
            {t("credits.font_secondary")} (
            <Link
              className="animated-cursor"
              to="https://github.com/coreyhu/Urbanist"
              target="_blank"
            >
              {t("credits.link")}
            </Link>
            )
          </li>
        </ul>
      </div>
      <CustomCursor />
    </div>
  );
}
